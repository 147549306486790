/**
 * ALLIEDSTRAND CONFIDENTIAL.
 *
 * Copyright 2018 Alliedstrand Corporation. All Rights Reserved.
 * =============================================================================
 */

import "../styles/styles.scss";

function ready(fn: () => void): any {
  // tslint:disable-next-line:no-string-literal
  if (window["attachEvent"] ? document.readyState === "complete" : document.readyState !== "loading") {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

/**
 * Checks if the web browser being used is Internet Explorer 10. If so, the
 * CSS display style on the outdated browser banner will set to a value which
 * makes it appear.
 */
function displayOutdatedBrowserBanner(): void {
  if (navigator.appVersion.indexOf("MSIE 10") !== -1) {
    const banner = document.getElementById("ascx-outdated-browser-banner");
    if (!!banner) {
      banner.style.display = "block";
    }
  }
}

ready(displayOutdatedBrowserBanner);
